import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import HomeArtists from "../components/homeartists";
import Contactform from "../components/contactform";
import {StaticImage} from "gatsby-plugin-image";
import Mouse from "../assets/Mouse.svg";
import Arrow from "../assets/Arrow1.svg";
import Play from "../assets/Play.svg";
import Gallery from "../assets/Gallery.svg"
import {Helmet} from "react-helmet";
import {Link} from "../../.cache/gatsby-browser-entry";

const Oferta = () => {
    return (
        <Layout>
            <Helmet>
                <title>Oferta na wesele / event - DJ Piotr Szymański</title>
                <meta name="description" content="Z chęcią zajmę się waszym weselem / eventem czy studniówką. Poprowadzę was od samego początku. Oferuję profesjonalne nagłośnienie oraz oświetlenie. " />
                <meta http-equiv="Content-type" content="text/html;charset=UTF-8" />
            </Helmet>
            <Hero className="">
                <StaticImage
                    src="../images/oferta-hero.png"
                    alt="Oferta"
                    layout="fullWidth"
                    className="hero"
                />
                <Header pageName="home"/>
                <div className="hero-title subpage">
                    <h1>Oferta na wesela i nie tylko</h1>
                    <h3 className="line-before">co obsługuję</h3>
                </div>
            </Hero>
            <section className="films-wrapper">
                <div className="film">
                    <StaticImage
                        src="../images/wesele.png"
                        alt="film z imprezy"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                </div>
                <div className="films-desc ">
                    <div className="films-title about-text bg-none">
                        <h2>Wesela</h2>
                        <span className="text-second">W każdym stylu</span>
                        <Arrow className="arrow-down icon-white arrow-sub-title"/>
                        <p>
                            Wesele to jedno z najważniejszych wydarzeń w Waszym życiu, w którym to Wy gracie rolę
                            głównych bohaterów. Moją rolą i tym samym marzeniem jako DJ-a jest poprowadzenie tego dnia w
                            taki sposób, aby stał się wyjątkowy i by był spełnieniem Waszych marzeń. Dzięki swojemu
                            wieloletniemu doświadczeniu i łatwości kontaktu z gośćmi, stworzę dla Ciebie uroczystość, o
                            jakiej śnisz! Marzysz o pięknej i wzruszającej oprawie muzycznej ślubu? Zdaje sobie sprawę z
                            tego, że wybór odpowiedniej oprawy muzycznej nie jest prosty, dlatego zapraszam Cię do
                            siebie. Jako DJ na wesele warszawa posiadam bogate doświadczenie muzyczne, które pozwoli mi
                            stworzyć niesamowite wydarzenie, w którym to Wy będziecie grać główne role, a ja poprowadzę
                            Was przez tą wyjątkową sztukę, którą zapamiętacie na lata!
                        </p>
                        <p>Co dostajecie w każdej cenie? Na terenie całej Polski oferujemy:
                            <ul>
                                <li>Do wyboru DJ-kę rustykalną / nowoczesną</li>
                                <li>Nagłośnienie NEXO - wysokiej klasy</li>
                                <li>Oświetlenie retro oraz ruchome głowy</li>
                                <li>Konsolę Pioneer</li>
                                <li>DJ / Konferansjer Piotr Szymański</li>
                            </ul>
                        </p>
                        <p>
                            Zapewne każdy, kto planuje wesele, zastanawia się, jakiego DJ wybrać. Jeśli chcecie, by
                            Wasze wesele trwało do białego rana, parkiet był pełen ludzi, a muzyka łączyła pokolenia, to
                            dobrze trafiliście. Pamiętajcie, że muzyka łączy i powoduje, że Wasz ślub może wznieść się
                            na inny wymiar. Chcecie się przekonać, jak stworzyć wesele marzeń za pomocą odpowiedniej
                            oprawy muzycznej? Skorzystaj w usługi DJ na wesele lublin, DJ na wesele warszawa i na
                            terenie całej Polski!
                        </p>

                </div>
            </div>
        </section>
    <section className="films-wrapper">
        <div className="film">
            <StaticImage
                src="../images/home-film.png"
                alt="film z imprezy"
                placeholder="blurred"
                layout="fullWidth"
            />
        </div>
        <div className="films-desc ">
            <div className="films-title about-text bg-none">
                <h2>Eventy</h2>
                <span className="text-second">impreza firmowa, event</span>
                <Arrow className="arrow-down icon-white arrow-sub-title"/>
                <p>
                    Oferuję wsparcie w prowadzeniu i realizacji imprez firmowych oraz eventów!
                </p>
                <p>
                    Potrzebujesz profesjonalnego prowadzenia eventu firmowego? Szukasz DJ na event w mieście Warszawa? A może szukasz nagłośnienia i oświetlenia, które stworzy odpowiedni klimat i atmosferę? Kogoś kto zintegruje Twój zespół? Dobry DJ na event stworzy dla Was wyjątkowe emocje! Projektujemy ciekawe scenariusze, a następnie dbamy o każdy szczegół ich realizacji. Nasze eventy na terenie całej Polski kochają zarówno mali, jak i duzi, nie mamy ograniczeń. To my kreujemy rozrywkę i zapewniamy zabawę na najwyższym poziomie! Z nami podniesiesz prestiż i innowacyjność tego typu spotkań. Organizacja eventu mazowsze i na terenie całej Polski – zdecyduj się na profesjonalizm na każdej płaszczyźnie!
                </p>
            </div>
        </div>
    </section>
            <section className="films-wrapper">
                <div className="film">
                    <StaticImage
                        src="../images/studniowka.png"
                        alt="film z imprezy"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                </div>
                <div className="films-desc ">
                    <div className="films-title about-text bg-none">
                        <h2>Studniówki</h2>
                        <span className="text-second">Niezapomniane wspomnienia</span>
                        <Arrow className="arrow-down icon-white arrow-sub-title"/>
                        <p>
                            Na 100 dni przed maturą każdy maturzysta zapomina o nauce i oddaje się szalonej zabawie w swoich ulubionych rytmach! Nie od dziś wiadomo, że oprawa muzyczna imprezy studniówkowej to wyznacznik udanej imprezy. Właśnie na tym polega bal – na tańcach do białego rana w rytm najlepszych przebojów W jaki sposób wybrać DJ na studniówkę? Czym powinien kierować się dobry DJ na studniówkę? Wybierając DJ na studniówkę powinniśmy zwrócić uwagę w pierwszej kolejności na jego doświadczenie, aby mieć gwarancję, że nas nie zawiedzie.</p>
                        <p> Oprócz muzyki, impreza studniówkowa powinna być odpowiednio nagłośniona i naświetlona, abyśmy mogli wspominać ją przez długie lata. DJ na studniówkę warszawa zadba o Twoją imprezę kompleksowo od strony muzyczno-technicznej! Pamiętaj, że studniówka to nie tylko odświętne stroje, smaczne jedzenie i zabawa do białego rana w gronie przyjaciół….To również wyjątkowy czas, który powinien mieć doskonałą oprawę muzyczną. Dla nas ważne jest to, aby każdy czuł się wyśmienicie i bawił na parkiecie w rytm swoich ulubionych przebojów! Dzięki częstemu prowadzeniu imprez i zdobytemu przez lata doświadczeniu DJ na studniówkę mazowieckie zadba o Waszą imprezę na najwyższym poziomie!
                        </p>

                    </div>
                </div>
            </section>
    <Contactform/>
</Layout>
)
    ;
};

export default Oferta;
